"use client";

import comscoreJs from "@vzmi/comscore-js/library-manifest.json";
import { getEsiVars } from "@yahoo-creators/edge";
import { Comscore, getComscoreC14, getComscoreFPDM } from "@yahoo-news/i13n";
import { useDeviceSize } from "@yahoo-news/util";
import { type FC, useMemo } from "react";
import { type EdgeSideIncludes } from "@/configs/esi";

import { useSeamlessSlugChange } from "@/hooks/events";
import { getClickThroughUrl } from "@/lib/article/util";
import { getGender } from "@/lib/gender";
import { getSpaceId } from "@/lib/i13n";
import { useRequestContext } from "@/lib/request/client";
import { type CaasArticle } from "@/types/Caas";

interface Props {
  article?: CaasArticle;
}

export const ComscoreConfig: FC<Props> = ({ article }) => {
  const { features, site } = useRequestContext();
  const deviceSize = useDeviceSize();
  const slugState = useSeamlessSlugChange(
    article && article !== undefined
      ? {
          article: article,
          index: 0,
          slug: new URL(getClickThroughUrl(article)).toString(),
        }
      : null,
  );

  const config = useMemo(() => {
    if (!deviceSize) {
      return null;
    }
    const gender = getGender();
    const { age, fpid } = getEsiVars<Pick<EdgeSideIncludes, "age" | "fpid">>({
      age: "",
      fpid: "",
    });
    const config = {
      c14: getComscoreC14(Number(age), gender),
      c5: String(getSpaceId({ article: slugState?.article, deviceSize, site })),
      comscoreJs: comscoreJs.assets.cs,
      fpdm: "",
      fpid: "",
    };

    if (features.comscoreUdm2) {
      config.fpdm = getComscoreFPDM(Number(age), gender);
      config.fpid = fpid;
    }

    return config;
  }, [slugState?.article, deviceSize, features.comscoreUdm2, site]);

  if (!config) {
    return null;
  }

  return <Comscore config={config} />;
};
