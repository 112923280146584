"use client";

import { useDeviceSize } from "@yahoo-news/util";
import { useMemo } from "react";
import { useBenjiConfig } from "@/hooks/useBenjiConfig";
import { getBenjiConfigForCreatorHomepage } from "@/lib/i13n";
import { useRequestContext } from "@/lib/request/client";

export const CreatorHomepageBenjiConfig = () => {
  const deviceSize = useDeviceSize({ updateOnResize: true });
  const requestContext = useRequestContext();

  const creatorHomepageConfig = useMemo(() => {
    if (deviceSize) {
      return getBenjiConfigForCreatorHomepage({
        deviceSize,
        requestContext,
      });
    }
  }, [deviceSize, requestContext]);

  useBenjiConfig({
    benjiConfig: creatorHomepageConfig,
    clearKeys: true,
    deviceSize,
    enableAdStacking: true,
    refreshLimit: 100,
    taboolaSetting: {
      pageType: "homepage",
      publisherId: "yahooweb-network",
    },
  });

  return null;
};
