import { getEsiVars } from "@yahoo-creators/edge";
import { GENDER } from "@yahoo-creators/i13n";
import { type EdgeSideIncludes } from "@/configs/esi";

export const getGender = () => {
  const { gender } = getEsiVars<Pick<EdgeSideIncludes, "gender">>({
    gender: "",
  });
  switch (gender) {
    case "0":
      return GENDER.UNSPECIFIED;
    case "1":
      return GENDER.FEMALE;
    case "2":
      return GENDER.MALE;
    default:
      return GENDER.UNKNOWN;
  }
};
