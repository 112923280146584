"use client";

import manifest from "@vzmi/evplayer-info/library-manifest.json";
import Script from "next/script";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  type FC,
  type ReactNode,
} from "react";

const EVPLAYER_URL =
  manifest.assets.evplayer || "https://s.yimg.com/rx/ev/prod/evplayer.js";

type ScriptState = "error" | "initial" | "loaded" | "loading";

const VideoPlayerContext = createContext<{
  lazyLoadScript: () => void;
  scriptState: ScriptState;
}>({
  lazyLoadScript: () => {},
  scriptState: "initial",
});

export const VideoPlayerProvider: FC<{
  children: ReactNode;
  strategy?: "afterInteractive" | "lazyOnload";
}> = ({ children, strategy = "lazyOnload" }) => {
  const [scriptState, setScriptState] = useState<ScriptState>(
    strategy === "lazyOnload" ? "initial" : "loading",
  );

  const lazyLoadScript = useCallback(() => {
    setScriptState((currentState) => {
      if (currentState !== "initial") {
        return currentState;
      }

      return "loading";
    });
  }, []);

  const onError = useCallback(() => {
    setScriptState("error");
  }, []);

  const onReady = useCallback(() => {
    setScriptState("loaded");
  }, []);

  return (
    <VideoPlayerContext.Provider value={{ lazyLoadScript, scriptState }}>
      {children}
      {scriptState === "initial" ? null : (
        <Script
          onError={onError}
          onReady={onReady}
          src={EVPLAYER_URL}
          strategy={strategy}
        />
      )}
    </VideoPlayerContext.Provider>
  );
};

export const useVideoPlayerScript = () => {
  const { lazyLoadScript, scriptState } = useContext(VideoPlayerContext);

  useEffect(() => {
    lazyLoadScript();
  }, [lazyLoadScript]);

  return { scriptState };
};
