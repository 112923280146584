import { type CaasArticle } from "@/types/Caas";

export function getArticleId(article: CaasArticle) {
  return article.data.contentMeta.contentI13n.uuid;
}

export function getClickThroughUrl(article: CaasArticle) {
  const { seoMeta } = article.data.contentMeta;
  const { mainEntityOfPage } = article.schema.default;
  return seoMeta.clickThroughUrl || seoMeta.canonicalUrl || mainEntityOfPage;
}
