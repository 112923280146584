"use client";

import ACookie from "@vzmi/acookie";
import { getEsiVars } from "@yahoo-creators/edge";
import { GENDER } from "@yahoo-creators/i13n";
import { parseCookie } from "@yahoo-news/util";
import { useEffect, useState } from "react";
import { AGE_BUCKETS } from "@/configs/benji";
import { getGender } from "@/lib/gender";

const getGenderString = (gender: GENDER) => {
  switch (gender) {
    case GENDER.FEMALE:
      return "1";
    case GENDER.MALE:
      return "2";
    default:
      return "0";
  }
};

interface PersonalizedAdData {
  ageBucket: number;
  ageInt: number;
  bot: string;
  hydrated: boolean;
  genderString: string;
  userConsents: string[];
  userJurisdiction: string;
}

export const useUserBenjiVars = () => {
  const [data, setData] = useState<PersonalizedAdData>({
    ageBucket: -1,
    ageInt: -1,
    bot: "0",
    genderString: "0",
    hydrated: false,
    userConsents: [],
    userJurisdiction: "",
  });

  useEffect(() => {
    const gender = getGender();
    const { age, bot, jurisdiction } = getEsiVars({
      age: -1,
      bot: "0",
      jurisdiction: "",
    });
    const ageInt = Number(age);
    const { A1S = "" } = parseCookie(document.cookie);
    const aCookie = new ACookie(A1S);
    const userJurisdiction = jurisdiction || aCookie?.value?.gucJurisdiction;
    const userConsents = aCookie?.value?.gucConsentTypes;
    const ageBucketIndex = AGE_BUCKETS.findIndex((range) => ageInt < range);
    const ageBucket = ageBucketIndex === -1 ? 0 : ageBucketIndex;

    const userData = {
      ageBucket,
      ageInt,
      bot,
      genderString: getGenderString(gender),
      hydrated: true,
      userConsents,
      userJurisdiction,
    };
    setData(userData);
  }, []);
  return data;
};
